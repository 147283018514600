.uuiTooltip {
    width: 100%;
    overflow: hidden;
}

.tooltip {
    margin: 0;
    white-space: break-spaces;
    word-wrap: break-word;
}
