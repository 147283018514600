@import '../../variables';

.fieldsContainer {
    height: 100%;
    overflow: auto;
    max-height: calc(100vh - 131px);
    position: relative;
    display: flex;
    flex-direction: column;
}

.fieldsContainerNoSecondary {
    height: 100% !important;
    overflow: auto;
    max-height: calc(100vh - 122px);
    position: relative;
}
.fieldsContainerNormal {
    composes: fieldsContainer;
    //background: $uuigraybackground;
}
.itemScreenSummaryWrapper {
    position: relative;
    background-color: $uuigraybackground;
    padding: 0 5px;
}
.itemScreenSummary {
    border: solid 1px #dadada;
    background-color: #fff;
    padding-bottom: 8px;
}
.errorsBoxWrapper {
    padding: 10px 10px 0 10px;
}
.errorsBox {
    border-width: 1px 1px 1px 4px;
    border-style: solid;
    border-color: #ac1822;
    padding: 5px 15px 2px 35px;
    position: relative;
}
.warningsBox {
    composes: errorsBox;
    border-color: #ea8f00;
}
.errorsTitle {
    font-weight: bold;
}
.errorsIndicatorIcon {
    position: absolute;
    left: 10px;
    top: 5px;
}
.errorsIndicatorSvgIcon {
    color: #ac1822;
    width: 18px;
    height: 18px;
}
.warningsIndicatorSvgIcon {
    composes: errorsIndicatorSvgIcon;
    color: #ea8f00;
}
.errorMessage {
    padding-bottom: 2px;
}
