.dialogRoot {
    min-width: 360px;
    z-index: 999999999 !important;
}

.dialogBody {
    background-color: #fff;
}

.dialogContent {
    text-align: center;
}

.heading {
    padding: 20px 24px 33px 24px;
    font-size: 20px;
    font-weight: 700;
}

.iconWrapper {
    margin-bottom: 44px;
}

.buttonWrapper {
    padding-top: 30px;
}

.messageBody {
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
}

.spinner {
    padding-bottom: 40px;
}
