.folderIconWrapper {
    vertical-align: top;
    width: 16px;
    height: 16px;
    padding-right: 8px;
}

.iconWrapper {
    composes: folderIconWrapper;
    display: inline-block;
    padding-top: 0px !important;
    padding-right: 0px;
}

.iconWrapperLink {
    composes: iconWrapper;
    cursor: pointer;
}

.icon {
    width: 16px;
    height: 16px;
}

.folderIcon {
    width: 16px;
    height: 16px;
}

.listLinkButton {
    padding: 0 !important;
    display: inline;
    min-width: inherit;
}

.listLinkButton:hover {
    background-color: inherit;
    text-decoration: underline;
}

.linkButtonWithFileIcon {
    max-width: calc(100% - 20px);
}

.linkButtonWithoutFileIcon {
    max-width: 100%;
}

.listLinkLabel {
    justify-content: left;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    white-space: pre;
    line-height: normal;
}

.iconWrapper + * {
    padding-left: 8px !important;
}

.iconWrapper:only-child {
    margin-right: 8px;
}

.folderContainer {
    margin-top: 1px;
    flex-grow: 1;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    font-size: 14px;
}

.matterLink:focus-visible {
    outline: none;
    border: solid 2px #474747;
    display: inline-block;
}

.listLinkButton:focus-visible {
    outline: none;
    border: solid 2px #474747;
    display: inline-block;
    border-radius: 0px;
}

.appearance {
    width: 50px;
    display: inline-block;
    color: #fff;
    border-radius: 21px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    padding: 2px 7px 2px 7px;
    letter-spacing: 1px;
    text-align: center;
}

.high {
    background: #e5202e;
}

.med {
    background: #ea8f00;
    color: #000;
}

.low {
    background: #ededed;
    color: #000;
}

.info {
    background: #007ac3;
    color: #fff;
}
