.inputNative {
    padding: 3px 28px 3px 3px !important;
}

.singleAutocompleteRoot {
    padding-right: 3px !important;
    padding-bottom: 3px !important;
}

.inputFocused {
    padding: 2px !important;
}

.endAdornment {
    right: 5px;
}

.iconButton {
    padding: 0;
}

.iconButtonSpyglass {
    padding: 1px;
}

.icon {
    width: 20px;
    height: 20px;
}

.iconSpyglass {
    composes: icon;
    padding-top: 2px;
    margin-left: 3px;
}

.iconLoading {
    composes: icon;
    margin-top: 4px;
    margin-left: 4px;
}

.paper {
    margin: 0;
    border-radius: 0;
    border: solid 1px #ccc;
}

.input {
    padding-top: 0;
    padding-bottom: 0;
}

.option {
    min-height: 32px;
    font-size: 0.875rem;
}

.option[data-focus='true'] {
    background-color: #e5f2fa;
}

.option[aria-selected='true'] {
    background-color: #f3f8fc;
}

.clearIndicator {
    padding: 2px;
}

.popupIndicator {
    visibility: hidden;
}

.optionPipe {
    color: blue;
}
