@import '../../variables';

.flyoutMenuIcon {
    margin-right: 4px;
    width: 31px !important;
    height: 31px !important;
}

.flyoutMenuHidden {
    visibility: hidden;
}

.tooltip {
    margin: 0px;
    white-space: break-spaces;
}

.flyoutMenuButtonActive {
    composes: flyoutMenuIcon;
    background-color: rgba(0, 122, 195, 0.08);
}

.flyoutMenuItem {
    color: $mediumblue;
    height: 32px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.flyoutMenuItemWordWrap {
    composes: flyoutMenuItem;

    max-width: 100%;
    & > div {
        & > span {
            flex-grow: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.flyoutMenuItem[aria-disabled='true'] {
    opacity: 1;
}

.flyoutMenuItemWithExtraSpace {
    composes: flyoutMenuItem;
    padding-left: 40px;
}

.flyoutMenuPaper {
    border: 1px solid #007261;
}

.flyoutMenuList {
    padding-top: 0px;
    padding-bottom: 0px;
}

.flyoutMenuListNarrow {
    composes: flyoutMenuList;
    max-width: 200px;
}

.menuIcon {
    min-width: 0px;
    margin-right: 8px;
    color: $mediumblue;
    width: 16px;
    height: 16px;
}

.listItemTextContainer {
    margin-top: 5px;
}

.listItemText {
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
}

.flyoutMenuItemIcon {
    width: 16px;
    height: 16px;
}

.flyoutBreadcrumbButton {
    padding: 0;
    min-width: 0;
}

.flyoutBreadcrumbButton:hover {
    background-color: unset;
    text-decoration: underline;
}

.flyoutBreadcrumbButton:active {
    color: #353535;
    outline: unset;
    border: unset;
    text-decoration: unset;
}

//flyout select menu styles
.flyoutSelectMenuRoot {
    color: $mediumblue;
    font-size: 14px;
    white-space: pre;
}

.flyoutSelectMenuRoot:focus {
    background-color: rgba(0, 122, 195, 0.4);
}

.flyoutSelectIcon {
    color: $mediumblue;
}

.enableScrollbarFlyoutPaper {
    overflow: auto;
    width: max-content;
    composes: flyoutMenuPaper;
}

.enableScrollbarFlyoutList {
    width: max-content !important;
    composes: flyoutMenuList;
    padding-right: 0px !important;
    margin-top: 8px;
}

.enableScrollbarFlyoutList li {
    padding: 0px 8px 4px 16px;
    height: auto;
}

.enableScrollbarFlyoutList li div {
    margin-top: 0px;
    margin-bottom: 0px;
    white-space: pre;
}
