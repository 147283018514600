.activeProfile {
    color: #005b92;
    background: #fff;
    border-radius: 0;
    border-top: 5px solid #85bc20;
    box-sizing: border-box;
    height: 56px;
    &:hover {
        color: #005b92;
        background: #fff;
        border-radius: 0;
        border-top: 5px solid #85bc20;
        box-sizing: border-box;
        height: 56px;
    }
}

.uploadCount {
    position: absolute;
    font-size: 8px;
    text-align: center;
    margin-top: 2px;
}

.uploadCountTitle {
    color: #007ac3;
}

.iconSuccess {
    color: #648d18;
}

.iconError {
    color: #ac1822;
}

.iconClear {
    color: #007ac3;
}

.iconWhite {
    color: #ffffff;
    margin-top: 2px;
}

.tooltip {
    margin: 0px;
    white-space: pre;
}
