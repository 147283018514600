@import '../../variables';

.dialogRoot {
    min-width: 360px;
}

.dialogPaperGreen {
    border: solid 2px #009881;
}

.dialogAppBar {
    position: relative;
    background-color: #009881;
}

.greenDialogAppBar {
    composes: dialogAppBar;
    background-color: #009881;
    height: 50px;
}

.dialogTitle {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
}

.dialogBody {
    background-color: #fff;
}

.dialogContent {
    padding: 0;
}

.dialogActions {
    background-color: $uuigraybackground;
    justify-content: space-between;
    padding: 16px;
}

.button {
    margin: 5px;
}

.iconChevron {
    width: 1.2em;
    height: 1.2em;
}

.iconCloseButton {
    margin-right: 6px;
    padding: 4px;
}

.iconStartButton {
    margin-left: 0;
    padding: 0;
}

.toolbar {
    padding: 0;
    min-height: 50px;
}

.tooltip {
    margin: 0;
    white-space: pre;
}

.appHeader {
    top: 56px !important;
}
