.title {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4;
    pointer-events: none;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
    line-height: 1;
}

.content {
    padding: 6px 0 7px;
    line-height: 1;
    font-size: 0.9rem;
    font-weight: 500;
}

.formRoot {
    width: 100%;
}
