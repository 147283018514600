.treeItemContent {
    padding: 5px 0;
    user-select: none;
}

.treeItemContent:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.treeItemLabel,
.treeItemLabel:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

.treeItem.currentlySelectedTreeItem > .treeItemContent {
    background-color: rgba(0, 0, 0, 0.2);
}

.checkIconWrapper {
    margin-top: 2px;
    margin-bottom: -2px;
}

.selectedTreeItem {
    margin-left: -20px;
}

.treeItemLabel {
    display: flex;
}

.checkIcon {
    color: green;
    font-size: 1rem;
    margin-right: 3px;
}

.selectedDisplayValue {
    font-style: italic;
    padding-left: 5px;
}

.iconContainer {
    width: 20px;
}

.iconContainer svg {
    font-size: 24px;
}
