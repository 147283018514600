.dropzoneWrapper {
    position: relative;
    overflow: hidden;
}

.dropzoneWrapper:focus {
    outline: none;
}

.dropzone {
    z-index: 2;
    position: absolute;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    outline: none;
    transition: border 0.24s ease-in-out;
}

.dropzoneNotActive {
    composes: dropzone;
    background-color: rgba(0, 0, 0, 0.3);
    border: 2px dashed #ffffff;
}

.dropzoneActive {
    composes: dropzone;
    padding: 4px;
    background-color: rgba(0, 0, 0, 0.65);
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.dropzoneActive::-webkit-scrollbar {
    display: none; /* Chrome/Safari/Webkit */
}

.activeBorder {
    border: 2px solid #ffffff;
    height: 100%;
}

.activeLabelWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #ffffff;
    width: 100%;
    height: 100%;
    font-weight: normal;
}

.activeLabel {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding: 4px 16px;
    width: 100%;
    word-break: break-all;
    text-align: left;
}

.twoLinesLabel {
    composes: activeLabel;
    -webkit-line-clamp: 2 !important;
}

.threeLinesLabel {
    composes: activeLabel;
    -webkit-line-clamp: 3 !important;
}

.activeChildren {
    z-index: 1;
}
