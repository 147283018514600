.blockUiContainer {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 1350;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-tap-highlight-color: transparent;
    opacity: 1;
    cursor: wait;
}

.blockUiMessageContainer {
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    transform: translateY(-50%);
    z-index: 1351;
}
