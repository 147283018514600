@import '../../variables';

.itemScreenViewRoot {
    padding: 10px;
    overflow: hidden;
}
.embeddedContainer {
    height: inherit;
}
.gridContainerLevel1 {
    width: calc(100% + 32px);
    padding-top: 8px;
}

.gridContainerLevel2 {
    composes: gridContainerLevel1;
}

.gridContainerLevel3 {
    width: calc(100% + 8px);
    background: $uuigraybackground;
    margin-left: 0px;
}

.showMoreWrapper {
    position: absolute;
    bottom: 1px;
    right: 5px;
}

.showButtonRoot {
    background-color: #fff;
    background-clip: padding-box;
    border: 4px solid hsla(0, 0%, 100%, 0.5);
    border-right-width: 1px;
    margin-right: 5px;
    font-size: 10px;
}

.showButtonRoot:hover {
    background-color: rgb(233, 245, 246);
}

.sectionGroupHeading {
    margin: 0;
    font-weight: 500;
    line-height: 1.6;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

h3.sectionGroupHeading {
    font-size: 1.125rem;
}

h4.sectionGroupHeading {
    font-size: 1rem;
    margin-top: 5px;
}

h5.sectionGroupHeading {
    font-size: 0.875rem;
    margin-top: 15px;
    margin-bottom: 15px;
}

.sectionLevel1 {
    margin-bottom: 10px;
    padding-bottom: 8px;
    border-bottom: solid 1px #d9d9d9;
}

.sectionLevel1:last-child {
    border-bottom: none;
    height: 100%;
}

.sectionLevel2 {
    padding: 0 0 8px 10px;
}

.sectionLevel3 {
    padding: 0 8px 0 15px;
}

.sectionLevel3:last-child {
    margin-bottom: 10px;
}

.tooltip {
    margin: 0;
}

.sectionContainer {
    margin-bottom: 10px;
    border: 1px solid lightgrey;
    padding-bottom: 6px;
}

.sectionHeading {
    margin-left: 8px;
    margin-top: 8px;
    margin-bottom: 5px;
}
