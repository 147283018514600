.saveAsInputControls {
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 36px;
    height: 36px;
}

.content {
    word-break: break-word;
}

.renameTabHelperText {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
}
