@import '../../variables';

.header {
    background-color: $uuigraybackground;
    padding: 0 0 0 0;
}

.primaryText {
    font-size: 1rem;
    font-weight: 700;
    white-space: pre;
}

.primaryBase {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    margin-top: -11px;
}

.primary {
    composes: primaryBase;
    margin-bottom: -11px;
}

.primaryNoSubtitleNoTag {
    composes: primaryBase;
    margin-bottom: -19px;
}

.secondary {
    display: flex;
    align-items: center;
    align-content: center;
    margin-top: 8px;
    margin-left: 24px;
    margin-right: 24px;
}

.leftChevronAndTextContainer {
    display: inline-flex;
    align-items: center;
    align-content: center;
    overflow: hidden;
    white-space: nowrap;
}

.leftChevron {
    display: inline-block;
    font-size: 2rem;
    margin-left: -9px !important;
    margin-right: -8px;
}

.subTitleAndTagContainer {
    display: inline-flex;
    align-items: center;
    align-content: center;
    width: 100%;
}

.subtitleBase {
    font-size: 12px;
    color: #353535;
    letter-spacing: 1px;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
    padding-top: 3px;
}

.subtitleHalf {
    composes: subtitleBase;
    margin-right: 16px;
    max-width: 50%;
    white-space: pre;
}

.subtitleFull {
    composes: subtitleBase;
    max-width: 100%;
    white-space: pre;
}

.tagBase {
    display: inline-block;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: 700;
    border: 0.075rem solid #979797;
    border-radius: 8px;
    color: black;
    margin-top: 1px;
    background-color: #ededed;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 8px 0 8px;
}

.tagHalf {
    composes: tagBase;
    max-width: 50%;
    white-space: pre;
}

.tagFull {
    composes: tagBase;
    max-width: 100%;
    white-space: pre;
}

.tooltip {
    margin: 0;
    white-space: break-spaces;
}

.fileIconContainer {
    margin-right: 0px;
    margin-left: 5px;
    padding: 0px;
}

.opsMenuIconContainer {
    display: inline-flex;
}

.quickFileIcon {
    width: 24px;
    height: 24px;
    padding: 4px;
    margin-bottom: 2px;
}

.secondaryContainer {
    padding: 0px;
}

.noKebobFilesContainer {
    margin-right: 4px;
    width: 31px !important;
    height: 31px !important;
}

.primaryTextWithOutOpsQF {
    font-size: 1rem;
    font-weight: 700;
    margin-right: 8px;
}
