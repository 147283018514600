.root {
    width: 100%;
    & > div {
        width: 100%;
        & > div {
            min-width: -webkit-fill-available;
            & > div {
                min-width: -webkit-fill-available;
                & > div > div > div > div > div > div {
                    border-radius: 1rem;
                    &:hover {
                        border-radius: 1rem;
                    }
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }
}
