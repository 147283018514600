@import '../../variables';

.listViewHeaderRoot {
    background-color: $uuigraybackground;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: solid 1px #ddd;
    border-top: solid 1px #eee;
}

.mainListSticky {
    padding-top: 0;
    position: sticky;
    top: 0;
    z-index: 3;
}

.embeddedListSticky {
    composes: mainListSticky;
    top: 136px;
    height: auto;
    overflow: auto;
    margin-top: 0px;
}

.iconRoot {
    color: #005b92;
    transform: scale(1.3);
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 0px;
    padding-bottom: 10px;
}

.iconSortbyArrow {
    top: 2px;
    width: 15px;
    height: 9px;
    transform: scale(0.8);
}

.listItemTextRoot {
    composes: listItemTextRoot from 'listViewItem.module.scss';
}

.checkboxContainer {
    composes: checkboxContainer from 'listViewItem.module.scss';
    margin-top: -12px;
}

.headerTextButton {
    padding: 0 7px 0 0;
    min-width: inherit;
    line-height: inherit;
    max-width: calc(100% - 12px);
}

.headerText {
    text-overflow: ellipsis;
    overflow: hidden;
    color: #474747;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    display: block;
    margin-right: 10px;
}

.textHeaderContent {
    text-overflow: clip !important;
    padding-bottom: 0px;
    height: 20px;
    font-size: 12px !important;
    height: fit-content;
}

.textHeaderContentNoCheckBox {
    text-overflow: clip !important;
    padding-bottom: 0px;
    height: fit-content;
    margin-bottom: 0px !important;
    font-size: 12px !important;
}

.headerTextNoButton {
    font-size: 12px;
    text-overflow: ellipsis !important;
    width: 100%;
    overflow: hidden;
}

.textContainerForHeader {
    margin-top: 4px !important;
    margin-bottom: 3px !important;
}

.textHeaderContentNoCheckBox:first-child {
    margin-top: 0px;
}

.textHeaderContent:first-child {
    margin-top: 0px !important;
}

.textHeaderContent:nth-child(2) {
    margin-top: 0px !important;
}

.textHeaderContainerNoCheckBox {
    margin-top: 4px;
    margin-bottom: 1px;
}

.textHeaderContainerNoCheckBox:nth-child(2) {
    margin-top: 0px;
    margin-bottom: 5px;
    & > div {
        margin-bottom: 0px !important;
    }
}

.textHeaderContainerNoCheckBox:last-child {
    margin-top: 0px;
    margin-bottom: 1px;
}

.textHeaderContainerWithCheckBox {
    margin-top: 0px;
    margin-bottom: 0px;
    & > div {
        margin-bottom: 0px !important;
    }
}

.textHeaderContainerWithCheckBox:nth-child(2) {
    margin-top: 4px;
    margin-bottom: 5px;
}

.textHeaderContainerWithCheckBox:last-child {
    margin-top: 5px;
    margin-bottom: 5px;
}
