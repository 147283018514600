.expandableInput {
    padding-bottom: 0;
}

.dialogTitle {
    padding-right: 50px;
}

.dialogText {
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 0;
    padding-top: 0;
}

.readMoreButtonContainer {
    text-align: right;
}

.readMoreButton {
    padding-top: 1px;
    padding-bottom: 1px;
}

.dialogCloseButton {
    position: absolute;
    right: 2px;
    top: 8px;
    color: #aaa;
}

.counterText {
    text-align: right;
    font-size: 0.7rem;
}

.charLimitWarningContainer {
    text-align: initial;
    width: auto;
    padding-top: 2px;
    font-size: 0.875rem;
    display: flex;
}

.wkWarningIcon {
    height: 10px;
    margin-right: 4px;
    width: auto;
    margin-top: 2px;
    color: #ea8f00;
}

.charLimitWarningMsg {
    color: #a96e02;
}

.outline {
    border: 2px solid #f3d6a1 !important;
}
