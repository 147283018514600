.quickSearchInputRoot {
    padding-right: 5px;
    font-size: 14px;
    height: 32px;
    border-radius: 0;
    background-color: #fff;
    margin-top: 0 !important;
    min-width: 120px;
    max-width: 320px;
}

.quickSearchInput {
    padding-top: 6px;
    padding-bottom: 6px;
}

.icons {
    color: #777;
    padding-top: 4px;
}

.iconButton {
    padding-bottom: 2px;
}

.closeIcon {
    font-size: 1.3rem;
    padding-bottom: 3px;
}

.iconSpyglass {
    height: 18px;
    width: 18px;
    font-size: 5em;
    margin-left: 3px;
}
