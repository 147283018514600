$font-stack: 'Calibri', sans-serif;

// Color constants
$mediumgray2: #666;
$mediumgray: #7e7f82;
$mediumlightgray: #aaa;
$lightblue: #c2ebf2;
$mediumblue: #007ac3;
$highlightblue: #337ab7;
$verylightgray: #f5f5f5;
$lightgray: #d1d3d4;
$action-color: #52a9d8;
$heading-color: #19679e;
$highlight-color: #85bc20;
$userbar-image-separator-color: #75a4c5;
$user-settings-dropdown-arrow-color: #ffffff;
$user-settings-text-icon-color: #696a6c;

// Development Mode Constants
$devmode-stripe-angle: -45deg;
$devmode-stripe-stop1: 40px;
$devmode-stripe-stop2: 80px;
$devmode-stripe-stop1-color: #3c80b0;
$devmode-stripe-stop2-color: #6399c0;

// Border constants
$lightgray-border: $lightgray solid 1px;
$transparent-border: transparent solid 1px;

// Timing constants
$global-search-fade-duration: 300ms;
$global-search-slide-duration: 350ms;

// Global search results constants
$global-search-results-max-width: 542px;
$global-search-results-max-height: 540px;
$global-search-results-text-max-width: $global-search-results-max-width - 74px;

// UUI colors
$uuigraybackground: #f6f6f6;
