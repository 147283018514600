.pickerCommon {
    min-width: 320px;
    width: 80%;
}

.pickerTitle {
    composes: pickerCommon;
    margin: 15px auto 5px;
    padding: 5px 5px 10px 5px;
}

.arrowButton {
    margin: 5px;
    background-color: #fff;
}
