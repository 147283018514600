.container {
    margin: 0px 0 10px 0px;
}

.listItemDragging {
    background: #ccc !important;
}

.listItem {
    border-bottom: solid 1px #e2e2e2;
    height: 40px;
}

.listItemIcon {
    min-width: 24px;
}
.dragHandle {
    padding-left: 10px;
    display: none;
}

.tabName {
    font-size: 14px;
    color: #474747;
    white-space: pre;
}

.tooltip {
    white-space: pre;
}
