@import '../../variables';

.secondaryAction {
    /*1. chrome with row actions or OC with either row actions or upload icon.*/
    padding-right: 39px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.noAction {
    /*0. chrome = no upload icon with no kebob, no checkbox*/
    padding-right: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.secondaryActionOC {
    /*2. OC with row actions and upload icon.*/
    padding-right: 71px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.checkboxContainer {
    min-width: 0px;
    float: left;
    margin-top: -12px;
}

.checkboxContainerWithBreadCrumb {
    composes: checkboxContainer;
    margin-top: 8px;
}

.listItemRoot:hover {
    background-color: $uuigraybackground;
}

.checkedRow {
    background-color: $uuigraybackground;
}

.listItemContainer {
    position: relative;
}

.enableProgress {
    animation: spin-icon 2s linear infinite;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 30%;
    left: 45%;
}

/* this style is needed for IE11 */
.listItemTextRoot {
    width: 100%;
    margin-top: 3px;
}

.folderRow {
    margin-bottom: 0;
    min-height: 32px;
}

.folderRowText {
    margin-top: 3px;
    margin-bottom: 0px;
}

.iconContainer {
    right: 0px;
    pointer-events: auto;
    opacity: 1;
}

.disableIconContainer {
    right: 0px;
    pointer-events: none;
    opacity: 0.4;
}

.quickFileIcon {
    width: 24px;
    height: 24px;
    padding: 4px;
}

.fileIconContainer {
    margin-right: 5px;
    padding: 0px;
}

.spinnerIcon {
    color: #000000;
}

@keyframes spin-icon {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.tooltip {
    margin: 0;
    white-space: break-spaces;
}

.breadcrumb {
    font-size: 12px;
    color: #757575;
    white-space: nowrap;
    overflow: hidden;
    line-height: normal;
    margin-top: 5px;
    margin-bottom: 5px;
}

.noBulkOpsRow {
    cursor: default;
}
