.tabsRoot {
    background-color: #eee;
    min-height: 38px;
    position: sticky;
    top: 0;
    padding-left: 25px;
}

.tabRoot {
    min-height: 38px;
    line-height: 1.3;
    padding: 8px 12px 4px;
    border-left: solid 1px #ddd;
    border-right: solid 1px #ddd;
}

.tabSelected {
    background-color: #fff;
}

.tabPanelRoot {
    background: #fff;
    padding: 0;
}

.tabIndicator {
    bottom: auto;
    top: 0;
    height: 5px;
}
