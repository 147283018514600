.noResults {
    text-align: center;
}

.listViewRoot {
    padding: 0;
}

.listViewItems {
    background: #fff;
}

.pagerSticky {
    padding-top: 0;
    position: sticky;
    top: 84px;
    z-index: 3;
    background-color: #ffffff;
}

.mainListPagerSticky {
    padding-top: 0;
    position: sticky;
    top: 0px;
    z-index: 3;
    background-color: #ffffff;
}
