@import '../../variables';

.input {
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 16px;
}

.inputMultiline {
    padding: 3px;
    height: 90px !important;
    overflow-y: auto !important;
}

.readOnlyInputMultiline {
    padding: 3px;
    overflow: hidden !important;
}

.htmlInput {
    padding: 3px;
}

.readOnlyHtmlInput {
    padding: 1px 3px 0 0;
    overflow: hidden !important;
}

.editableInput {
    composes: input;
    border: solid 1px #ccc;
    padding: 3px;
    background-color: #fff;
}

.editableInput:hover {
    border-color: $mediumblue;
}

.editableInputWithError {
    composes: editableInput;
    border-color: #ac1822;
}

.editableInputWithWarning {
    composes: editableInput;
    border-color: #ea8f00;
}

.editableInputFocused {
    border: solid 2px #000 !important;
    padding: 2px !important;
}

.editableInputFocusedWithError {
    composes: editableInputFocused;
    border-color: #ac1822 !important;
}

.editableInputFocusedWithWarning {
    composes: editableInputFocused;
    border-color: #ea8f00 !important;
}

.editableInputWithError:hover,
.editableInputFocusedWithError:hover {
    border-color: #ac1822;
}

.editableInputWithWarning:hover,
.editableInputFocusedWithWarning:hover {
    border-color: #ea8f00;
}
