.tabPanelContainer {
    height: calc(100vh - 162px);
    overflow-y: auto;
    overflow-x: hidden;
}

.tabHeightCommon {
    min-height: 40px;
}

.tab {
    composes: tabHeightCommon;
    color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
}

.tabsRoot {
    composes: tabHeightCommon;
    background-color: #009881;
    white-space: pre;
}

.selectedTab {
    background-color: #fff;
    color: #353535 !important;
}
