.button {
    border: 1px solid #005b92;
    padding: 5px 3px 5px 8px;
}

.actionLabel {
    color: #005b92;
    font-size: 12px;
    font-weight: normal;
}

.addIcon {
    width: 14px;
    height: 14px;

    g {
        rect {
            fill: none;
        }
        path {
            fill: #007ac3;
        }
    }
}
