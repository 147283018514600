.addIcon {
    width: 14px;
    height: 14px;

    g {
        rect {
            fill: none;
        }
        path {
            fill: #007ac3;
        }
    }
}

.ellipsisIcon {
    width: 13px;
    height: 3px;

    circle {
        fill: #007ac3;
    }
}

.openInDesktopIcon {
    width: 16px;
    height: 16px;
}
