@import '../../../variables';

.root {
    border: 1px solid #dadada;
    overflow: auto;
    background-color: white;
    max-height: 270px;
}

.endAdornment {
    left: 6px;
}

.endAdornment:hover {
    background: transparent;
}

.group {
    margin-left: 7px;
    padding-left: 18px;
    border-left: 1px solid #dadada;
}

.folderText {
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 0;
    padding-top: 0;
}

.inputFocused {
    padding-top: 2px;
    padding-left: 2px;
    padding-bottom: 2px;
}

.inputNative {
    padding: 3px 28px 0px 3px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.textFieldRoot {
    padding-right: 3px !important;
    padding-bottom: 3px !important;
}

.arrowUpIcon {
    color: $mediumblue;
    cursor: pointer;
}

.treeItemRoot {
    display: table-row;
}

.treeItemRoot.selectedTreeItem > .treeItemContent > .treeItemlabel {
    background-color: #f3f8fc;
    color: $mediumblue;
    font-weight: 500;
}

.treeItemlabel {
    color: $mediumblue;
    padding: 5px;
    font-size: 14px;
    white-space: nowrap;
    &:hover {
        background-color: #e5f2fa;
    }
}
