.paper {
    padding: 4px 8px 4px 8px;
    border-radius: 0;
}

.arrow {
    position: absolute;
    font-size: 7px;
    width: 3em;
    height: 3em;
    &:before {
        content: '';
        margin: auto;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
    }
}

.popper {
    z-index: 9;
}

.popper[x-placement*='bottom'] > .arrow {
    top: 0;
    left: 0;
    margin-top: -0.9em;
    width: 3em;
    height: 1em;
    &:before {
        border-color: transparent transparent #fff transparent;
        border-width: 0 1em 1em 1em;
    }
}

.message {
    color: #707070;
    font-size: 12px;
    margin-left: 7px;
    margin-right: 15px;
    line-height: normal;
    padding-top: 2px;
}

.uploadingMessage {
    composes: message;
    margin-left: 0px;
    margin-right: 0px;
}

.uploadMessageWithError {
    composes: uploadingMessage;
    padding-top: 4px;
}

.errorIcon {
    width: 16px;
    height: 16px;
}

.errorIcon > g > path {
    fill: #ac1822 !important;
}

.errorMessageContainer {
    border-bottom: 1px solid #d1d1d1;
}

.errorMessage {
    display: flex;
    padding-bottom: 4px;
}

.closeIconButton {
    margin-top: 2px;
    width: 12px;
    height: 12px;
}
