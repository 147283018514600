.rowContainer {
    display: flex;
    margin-left: 30px;
    margin-bottom: 5px;
}

.noCheckBoxRowContainer {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
}

.textContent {
    flex-grow: 1;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    font-size: 14px;
    margin-right: 16px !important;
    color: #474747;
    line-height: normal;
    height: fit-content;
}

.leftTextContent {
    composes: textContent;
    margin-right: 5px;
    margin-top: 0px !important;
}

.rightTextContent {
    composes: textContent;
    text-align: left;
    margin-top: 0px !important;
}

.tooltip {
    margin: 0;
    white-space: break-spaces;
    word-break: break-word;
}

.noCheckBoxRowContainer:last-child {
    margin-bottom: 4px;
    margin-top: 0px;
}

.noCheckBoxRowContainer:nth-child(2) {
    margin-bottom: 5px;
    margin-top: 0px;
    height: 16px;
}

.rowContainer:last-child {
    margin-bottom: 4px;
}

.rowContainer:nth-child(2) {
    margin-bottom: 5px;
    margin-top: 5px;
}

.rowContainer:focus {
    outline: none;
    border: solid 2px #474747;
}
