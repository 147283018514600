.container {
    border-bottom: solid 1px #dadada;
    flex-wrap: nowrap;
}

.leftItem {
    padding: 10px;
    white-space: nowrap;
}

.rightItem {
    padding: 10px;
    border-left: solid 1px #dadada;
    white-space: nowrap;
}

.count {
    height: 18px;
    background: #353535;
    color: #fff;
    min-width: 37px;
    margin-right: 3px;
}

.clearAll {
    margin-left: 10px;
}

.buttonLabel {
    white-space: nowrap;
}
