@import '../../variables';

.outLinedButton {
    border-radius: 0px;
    margin-left: auto !important;
}

.outLinedButtonWhite {
    composes: outLinedButton;
    background-color: #ffffff;
}

.outLinedButton:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.outLinedButton0 {
    margin: 0px 16px 0px 16px !important;
    background-color: #ffffff;
}

.outLinedButton0:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
}

.buttonLabel {
    font-family: 'Fira Sans';
    font-size: 14px;
    font-weight: 400;
}

.dialogTitleRoot {
    margin: 0;
    background-color: #007261;
    color: #fff;
    float: left;
    padding: 10.5px 8px 10.5px 16px;
    line-height: 1.5em;

    & h2 > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.dialogTitleErrorRoot {
    background-color: #e5202e;
}

.closeIconRoot {
    font-size: 1rem;
    width: 12px;
    height: 12px;
}

.closeIcon {
    color: #fff;
    padding: 8px;
}

.dialogActions {
    position: relative;
    justify-content: flex-start !important;
    background-color: $uuigraybackground;
    padding: 16px 16px 16px 0px;
}

.containedButtons {
    margin-left: 16px !important;
}

.containedButtons:hover {
    background-color: #409bd2;
}

.contentRoot {
    word-break: break-word;
    min-width: 296px;

    & > p {
        font-weight: 500;
        margin-bottom: 0px;
        white-space: break-spaces;
    }

    &,
    .dialogTitleRoot + & {
        padding: 16px;
    }
}

.opaqueBackdrop {
    background-color: #aaa;
}

.dialogPaperRoot {
    width: 100%;
    margin: 16px;
    border: 1px solid #007261;
    border-radius: 0 !important;
}

.titleText {
    font-size: 20px;
    font-weight: 400;
}

.titleTextContainer {
    max-width: calc(100% - 32px);
    float: left;
    height: 28px;
}

.closeIconContainer {
    float: right;
    max-width: 29px;
}
