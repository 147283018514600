.buttonBase {
    font-weight: 400;
    font-size: 14px;
}

.buttonOutlined {
    composes: buttonBase;
    background-color: #fff;
    margin-left: 0px !important;
}

.button {
    border-radius: 0;
    height: 32px;
}

.containedButton {
    margin-right: 16px;
    margin-left: 0px !important;
}

.buttons {
    margin-right: auto;
    margin-left: 0px !important;
}
