.pickerPaper {
    composes: pickerCommon from 'transferBasket.module.scss';
    overflow: auto;
    margin: 5px auto;
    height: calc((100vh - 400px) / 2);
    min-height: 200px;
    border: 1px solid #d8d8d8;
    box-shadow: none;
    border-radius: 0px;
}

.pickerPaperFullHeight {
    composes: pickerPaper;
    /* TODO: GET RID OF IMPORTANT */
    height: calc(100vh - 200px) !important;
}
