@import '../../variables';

.ol {
    flex-wrap: inherit;
    margin: 10px 10px 0 16px;
    overflow: hidden;
}

.li {
    /* Beginning of string */
    text-align: left;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    color: $mediumblue;
    cursor: pointer;
}

.li:hover {
    background-color: unset;
    text-decoration: underline;
}

.li:last-child:hover {
    text-decoration: none;
}
.li:active {
    color: #353535;
    outline: unset;
    border: unset;
    text-decoration: unset;
}

.li:first-child {
    white-space: nowrap;
    overflow: visible;
    text-overflow: ellipsis;
}

.li:last-child {
    color: rgba(0, 0, 0, 0.87);
    cursor: default;
    min-width: 0;
    :focus {
        outline: none;
    }
}

.li:last-child:hover {
    pointer-events: none;
    background-color: unset;
}

.separator {
    margin-left: 0px;
    margin-right: 0px;
}

.bcTooltip {
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

.breadcrumbName:focus-visible {
    outline: none;
    border: solid 2px #474747;
}

.breadcrumbName:focus {
    outline: none;
}
