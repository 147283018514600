.level3FieldRow {
    padding-right: 0px !important;
}

.fieldRowContainer {
    margin: 2px 8px 2px 8px;
    padding: 0px 8px 0px 8px !important;
}

.fieldRowContainer:nth-of-type(odd) {
    background-color: #f6f6f6;
}

.fieldRowContainer:nth-of-type(even) {
    background-color: #ffffff;
}
