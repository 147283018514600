@import '../../variables';

.dialogRoot {
    border: 1px solid #007261;
    border-radius: 0 !important;
    width: 100%;
    margin: 32px 16px 32px 16px;
}

.closeIcon {
    color: #fff;
    padding: 8px;
}

.dialogTitleRoot {
    margin: 0;
    height: 49px;
    background-color: #007261;
    color: #fff;
    width: 100%;
    padding: 10.5px 8px 10.5px 16px;
}

.cancelButton {
    border-color: #007ac3;
    background-color: #ffffff;
    color: #007ac3;
    position: absolute;
    border-radius: 0px;
    font-weight: 400;
}

.actionButton {
    border-color: #007ac3;
    background-color: #007ac3;
    color: #fff;
    position: absolute;
    left: 16px;
    border-radius: 0px;
    font-weight: 400;
}

.actionButton:hover {
    background-color: #409bd2;
}

.dialogActionsRoot {
    position: relative;
    height: 64px;
    max-height: 64px;
    background-color: $uuigraybackground;
    padding: 16px;
}

.secondActionButton {
    composes: actionButton;
    left: 70px;
    font-weight: 400;
}

.title {
    margin-right: 20px;
    font-size: 20px;
    white-space: pre;
}

.closeIconRoot {
    font-size: 1rem;
    width: 12px;
    height: 12px;
}

.actionDialogContent {
    padding: 16px;
    white-space: break-spaces;
}

.titleTextContainer {
    max-width: calc(100% - 32px);
    float: left;
    height: 28px;
}

.closeIconContainer {
    float: right;
    max-width: 29px;
}

.tooltip {
    white-space: pre;
}
