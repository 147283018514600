.datePickerUUIInput {
    width: 100%;
}

.closeIcon {
    font-size: 1rem;
}

.calendarIcon {
    font-size: 1.1rem;
    color: rgba(0, 0, 0, 0.45);
}

.reactDatePicker {
    z-index: 1400 !important;
    & > div > div {
        border-radius: 0;
    }
    & > div > div > div > div > div > div {
        border-radius: 1rem;
        &:hover {
            border-radius: 1rem;
        }
        &:focus {
            outline: none;
            border: 1px solid black;
            -webkit-box-shadow: 0px 0px 5px #4d90fe;
            box-shadow: 0px 0px 0px #4d90fe;
        }
    }
}

.datePicker {
    width: 100%;
}

.dateFieldIcons {
    display: contents;
}
