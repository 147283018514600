.container {
    display: flex;
    padding: 10px 5px 0px 10px;
}

.savedViewsDropdown {
    flex: 1;
    text-align: right;
    display: inline-flex;
    margin-left: auto;
    max-width: fit-content;
}

.savedViewsDropdownControl {
    max-width: calc(100vw - 205px);
}

.searchViewRow {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}

.quickSearchWrapper {
    float: left;
    margin-right: 16px;
    width: '-webkit-fill-available';
}

.viewLabel {
    margin-top: 6px;
    padding-right: 8px;
}

.savedViewsDropdownContainer {
    display: inline-flex;
    margin-left: auto;
}
