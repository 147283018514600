@import '../../variables';

.tabRoot {
    min-height: 40px;
    line-height: 1.3;
    padding: 6px 16px 4px;
    color: $mediumblue;
    opacity: 1;
    font-weight: normal;
    margin-top: 2px;
    white-space: pre;
}

.tabRoot:not(.tabSelected):not(.subTabRoot):hover {
    background-color: #fff;
    border-right: 1px #dadada solid;
    border-left: 1px #dadada solid;
    border-top: 1px #dadada solid;
    padding: 8px 15px 3px;
    border-bottom: 2px solid #fff;
}

.tabRoot:not(.tabSelected):not(.subTabRoot):focus-visible {
    background-color: #f6f6f6;
    border: 2px solid #000000;
    padding: 6px 14px 2px;
    margin-top: 0;
}

.subTabRoot {
    composes: tabRoot;
    border: none;
    line-height: 1.3;
    padding: 2px 5px;
    min-width: 100px;
    min-height: 28px;
    background: $uuigraybackground;
    border: solid 1px #ccc;
    border-width: 1px 1px 1px 0;
    opacity: 0.7;
    font-weight: 500;
    color: #111;
}

.subTabRoot:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-width: 1px;
}

.subTabRoot:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.tabSelected {
    background-color: #fff;
    border-bottom: solid 1px #fff;
    border-left: solid 1px #dadada;
    border-right: solid 1px #dadada;
    color: #353535 !important;
}

.subTabSelected {
    background-color: $mediumblue;
    color: #fff;
}
