.formControl {
    width: 100%;
}

.uuiLabel {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4px;
    padding: 0 0 1px 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.64);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 1;
}

.tooltip {
    margin: 0;
    white-space: pre;
}

.validationError {
    color: #ac1822;
    padding-top: 2px;
}

.validationWarning {
    composes: validationError;
    color: #ea8f00;
}

.uuiLabelAsterisk {
    color: #e5202e;
    padding-left: 3px;
}

.msg {
    vertical-align: top;
}

.msgIcon {
    width: 18px;
    height: 16px;
    padding-right: 2px;
}
