.pagerBarRoot {
    width: 100%;
    display: flex;
}

.spacer {
    display: none;
}

.toolbar {
    height: 52px;
}

.pagerContainer {
    overflow-x: hidden;
    float: left;
}

.pageInfo {
    padding-top: 1px;
}

.pagerDisplayValue {
    white-space: nowrap;
}

.pagerActionsContainer {
    text-align: left;
    white-space: nowrap;
    padding-left: 8px;
}

.pagerButton {
    padding: 0;
    color: #005b92;
}

.totalPageNumberArticle {
    margin: 0 8px;
}

.pageNumberArticleStart {
    margin: 0 8px;
}

.pageNumberInput {
    width: 40px;
    text-align: center;
    height: 32px;
    padding: 0 2px;
    border-width: 1px;
}

.pageNumberInput:focus {
    outline: none;
    border: solid 2px #000 !important;
}

.buttonsContainer {
    padding: 9px 10px 0 0;
    margin-left: auto;
}

.icon {
    width: 12px;
}

.tooltip {
    margin: 0;
}
