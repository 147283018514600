.messageSuccess {
    background-color: #ffffff !important;
    color: #353535 !important;
    border-radius: 0;
    padding: 8px 8px 8px 16px;
    word-break: break-word;
    & > div {
        padding: 0px;
        margin-left: auto;
    }
    display: flex;
}

.outerRowContainer {
    border: 1px solid #648d18;
    border-left: 4px solid #648d18;
    padding-bottom: 24px;
}
.outerFailedRowContainer {
    border: 1px solid #e5202e;
    border-left: 4px solid #e5202e;
}
.outerInfoRowContainer {
    border: 1px solid #007ac3;
    border-left: 4px solid #007ac3;
    padding-bottom: 24px;
}
.clearAllLink {
    cursor: pointer;
    font-size: 12px;
}
.successIcon {
    height: 16px;
    font-size: 16px;
    margin-right: 6px;
    margin-top: 1px;
}

.statusMessage {
    font-weight: 500;
    font-size: 14px;
}

.fullScreenDialogContent {
    padding-top: 0px !important;
}
.headerContainer {
    margin-left: 1px;
    padding-bottom: 5px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #eeeeee;
    padding-top: 8px;
    z-index: 5;
    display: flex;
}
.header {
    font-size: 21px;
    font-weight: 500;
}
.statusBlocks {
    background-color: #ffffff;
    padding: 25px 10px 10px 10px;
}

.emptyDialog {
    padding: 16px;
    font-weight: 400;
    width: fit-content;
}

.backChevron {
    font-size: 16px;
    padding-right: 1px;
}

.newElement {
    border: 1px solid #007ac3;
    border-left: 4px solid #007ac3;
}
.newElement:last-child {
    padding-bottom: 0px;
}
