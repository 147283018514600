@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Fira Sans Light'), local('FiraSans-Light'), url('fonts/fira-sans-v10-latin-300.woff2') format('woff2'),
        url('fonts/fira-sans-v10-latin-300.woff') format('woff');
}

@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Fira Sans Regular'), local('FiraSans-Regular'),
        url('fonts/fira-sans-v10-latin-regular.woff2') format('woff2'),
        url('fonts/fira-sans-v10-latin-regular.woff') format('woff');
}

@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    src: local('Fira Sans Medium'), local('FiraSans-Medium'), url('fonts/fira-sans-v10-latin-500.woff2') format('woff2'),
        url('fonts/fira-sans-v10-latin-500.woff') format('woff');
}

@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Fira Sans Bold'), local('FiraSans-Bold'), url('fonts/fira-sans-v10-latin-700.woff2') format('woff2'),
        url('fonts/fira-sans-v10-latin-700.woff') format('woff');
}

.react-datepicker__close-icon::after {
    background-color: #ccc;
    font-family: Arial;
    content: '\0058';
    padding: 3px 4px 2px;
}
