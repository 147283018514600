.inputFocused {
    padding-top: 2px;
    padding-left: 2px;
    padding-bottom: 0 !important;
}

.inputNative {
    padding: 3px 28px 3px 3px !important;
}

.multipleAutocompleteRoot {
    padding-right: 3px !important;
    padding-bottom: 3px !important;
}

.iconButton {
    padding: 0;
}

.icon {
    width: 20px;
    height: 20px;
}

.iconLoading {
    composes: icon;
    margin-top: 4px;
    margin-left: 4px;
}

.paper {
    margin: 0;
    border-radius: 0;
    border: solid 1px #ccc;
}

.input {
    padding-top: 0;
    padding-bottom: 0;
}

.option {
    min-height: 32px;
    font-size: 0.875rem;
    word-break: break-all;
}

.option[data-focus='true'] {
    background-color: #e5f2fa;
}

.option[aria-selected='true'] {
    background-color: #f3f8fc;
}

.clearIndicator {
    padding: 2px;
}

.optionPipe {
    color: blue;
}

.tagSizeSmall {
    background-color: #e5f2fa;
    height: 25px;
    max-width: 210px;
    cursor: default !important;
}

.popupIndicator {
    visibility: hidden;
}

.endAdornment {
    right: 5px;
    position: inherit;
    display: none;
}
.readOnlyClass {
    display: none;
}
.arrowUpIcon {
    color: #353535;
    cursor: pointer;
}

.chipClearIcon {
    color: #474747;
}

.tag {
    cursor: pointer;
}

.inputFocused {
    padding: 0px;
}

.iconButtonSpyglass {
    padding: 1px;
}

.iconSpyglass {
    composes: icon;
    padding-top: 2px;
    margin-left: 3px;
}
