.progressLoaderContainer {
    width: 26px;
    margin-left: 9px;
    margin-top: -3px;
    margin-right: -4px;
}

.wkIcons {
    width: 16px;
    height: 16px;
    margin-left: 5px;
    margin-top: -2px;
}

.documentName {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}

.documentNameSpan {
    margin-left: 7px;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.docInfo {
    flex: 1;
    width: -webkit-fill-available;
    padding-bottom: 16px;
}

.docInfoNoProgress {
    flex: 1;
    width: -webkit-fill-available;
    margin-left: 41px;
    padding-bottom: 0px;
}

.rowContainer {
    padding: 0px 0px 8px 0px;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    font-size: 12px;
    margin-left: 3px;
}

.breadCrumbWrapper {
    flex-wrap: nowrap;
}

.breadCrumbContainer {
    flex: 1;
    overflow: hidden;
    width: inherit;
}

.breadcrumb {
    padding-right: 5px;
}

.hoursDisplay {
    color: #474747;
    font-size: 12px;
    text-align: right;
    width: max-content;
}

.errorMessage {
    color: #ac1822;
}

.errorIcon {
    height: 16px;
    width: 16px;
    font-size: 18px;
    margin-right: 7px;
    float: left;
}

.errorMsgTxt {
    margin-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    padding-right: 8px;
    margin-top: 0px;
    max-width: -webkit-fill-available;
    padding-bottom: 16px;
}

.actionLinkContainer {
    width: max-content;
    margin-left: 16px;
    margin-top: -2px;
    height: 22px;
    text-align: right;
    font-size: 12px;
    cursor: pointer;
}

.docInfoName {
    width: -webkit-fill-available;
    margin-left: 8px;
    padding-right: 8px;
}
.docInprogressTooltip {
    flex: 1;
    overflow: hidden;
}

.entityContextTooltip {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.responsiveBreadcrumb {
    padding-bottom: 24px;
}
