.editsAppliedRoot {
    padding: 10px;
    background-color: #009881;
    color: #fff;
}

.chevronIcon {
    color: #fff;
}

.infoIconWrapper {
    padding-left: 10px;
}

.infoIcon {
    display: flex;
    height: 20px;
}

.buttonWrapper {
    margin-left: 3px;
}

.editsAppliedButton {
    background-color: #ffffff;
    color: #007ac3;
    font-weight: 400;
}

.tooltipText {
    font-size: 11px;
}

.tooltip {
    white-space: pre;
}
