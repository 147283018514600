@import '../../variables';

.buttonRowRoot {
    background-color: $uuigraybackground;
    padding: 5px;
}

.buttonRowItems {
    width: 100%;
    padding-top: 8px !important;
    display: flex;
    flex-wrap: nowrap;
}

.button {
    margin: 0 5px;
    font-weight: 400;
    margin-left: auto !important;
    height: 32px !important;
}

.buttonText {
    max-width: calc(100vw - 232px);
    vertical-align: middle !important;
}

.quickSearchWrapper {
    padding-left: 5px !important;
    float: left;
}

.button:hover {
    background-color: #003d61;
}

.button:focus {
    outline: solid 2px #000 !important;
    background-color: #007ac3;
}

.button:active {
    outline: 0px !important;
    background-color: #005b92;
}

.addIcon {
    width: 14px;
    height: 14px;

    g {
        rect {
            fill: none;
        }
        path {
            fill: #fff;
        }
    }
}
