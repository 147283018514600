@import '../../../variables';

.container {
    margin: 0px;
}

.filterPickerContents {
    margin: 0px;
    padding: 16px 0px 0px 16px;
    width: 100%;
}

.filterName {
    margin-right: 0px;
    padding: 0px !important;
    padding-right: 16px !important;
}

.filterPart {
    padding: 8px 0px 0px 0px !important;
}

.radioGroup {
    flex-direction: row;
}

.rating {
    color: $mediumblue;
}

.fullWidthFilterPart {
    width: calc(100% - 75px);
    display: inline-block;
}

.addIcon {
    margin-left: 8px;
}

.selectMenu {
    border-radius: 0px;
    height: 32px;
}

.borderLine {
    margin: 8px 20px 8px 0px;
    border-top: solid 1px #ccc;
    width: inherit;
}

.borderLineContainer {
    padding: 0px !important;
}

.datePickerContainer {
    margin-right: 22px;
    margin-top: -16px;
}

.buttonRoot {
    padding-top: 8px !important;
    height: auto;
    color: #007ac3;
    padding-left: 16px;
}

.datePickerContainer input[type='text'] {
    font: 400 14px Arial;
    padding-left: 10px;
    font-size: 1rem;
    align-items: center;
    font-family: Fira Sans, Helvetica, Arial, sans-serif;
}

.filterPartSecondRow {
    composes: filterPart;
    padding-left: 8px !important;
}
