.radioContainer {
    margin-left: 10px;
    padding-top: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 18px;
}

.functionTabTitle {
    font-weight: 600;
    display: block;
}

.grid {
    max-width: 100% !important;
    word-break: break-word;
}
