@import '../../variables';

.tabsRoot {
    background-color: #f6f6f6;
    min-height: 38px;
    box-shadow: 0 -1px 0 0 #dadada inset;
    padding-top: 10px;
    position: sticky;
    top: 0;
    z-index: 1150;
    color: $mediumblue;
    font-weight: normal;
    display: flex;
    margin-top: 1px;
}

.subTabsRoot {
    composes: tabsRoot;
    box-shadow: none;
    background: #fff;
    padding: 10px 0 0 0;
    margin-top: 0;
    position: sticky;
    top: 38px;
    z-index: 1150;
}

.tabIndicator {
    bottom: auto;
    top: 2px;
    height: 4px;
}

.subTabIndicator {
    display: none;
}

.tabsScrollButtons {
    opacity: 1 !important;
    border-bottom: solid 1px #dadada;
}

.tabsScrollButtons[class*='Mui-disabled'] > svg {
    opacity: 0 !important;
}
