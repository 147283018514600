.checkBox {
    margin-left: -10px;
    margin-top: 10px;
    margin-bottom: -2px;
}

.error {
    color: #ac1822 !important;
}

.warning {
    color: #ea8f00 !important;
}
