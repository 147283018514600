.tabRoot {
    white-space: nowrap;
}

.tabRoot[hidden] {
    display: none;
}

.tabWrapper {
    overflow: hidden;
    align-items: normal;
    margin: auto;
    padding: 0px 4px 0px 4px;
}
