@import '../../../variables';

.container {
    border: solid 1px #ccc;
    height: 32px;
    line-height: 1.1875em;
    margin-top: 16px;
}

.container:hover {
    border-color: $mediumblue;
}

.errorContainer {
    composes: container;
    border-color: #ac1822;
}

.warningContainer {
    composes: container;
    border-color: #ea8f00;
}

.errorContainer:hover {
    border-color: #ac1822;
}

.warningContainer:hover {
    border-color: #ea8f00;
}

.dropdown {
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 27px;
    font-size: 14px;
    padding-left: 5px;
}

.dropdown:focus {
    background-color: transparent;
    border: solid 2px #000 !important;
    margin-left: -1px;
}

.errorDropdown {
    composes: dropdown;
}

.warningDropdown {
    composes: dropdown;
}

.errorDropdown:focus {
    border-color: #ac1822 !important;
}

.warningDropdown:focus {
    border-color: #ea8f00 !important;
}

.paper {
    max-height: 270px;
    border-radius: 0px;
}

.menuItem:hover {
    background-color: #e5f2fa;
}

.menuItem[aria-selected='true'] {
    background-color: #f3f8fc;
}
