.dropDownInputFocused {
    padding-top: 2px;
    padding-left: 2px;
    padding-bottom: 0 !important;
}

.dropDownNativeInput {
    padding: 4px !important;
}

.endAdornment {
    right: 5px;
}

.paper {
    margin: 0;
    border-radius: 0;
    border: solid 1px #ccc;
}

.dropdown {
    padding-top: 0;
    padding-bottom: 0;
}

.option {
    min-height: 32px;
    font-size: 0.875rem;
}

.option[data-focus='true'] {
    background-color: #e5f2fa;
}

.option[aria-selected='true'] {
    background-color: #f3f8fc;
}

.clearIndicator {
    padding: 2px;
    margin-top: 2px;
}

.popupIndicator {
    padding: 0;
    margin-top: 2px;
}
