.saveAsInputControls {
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 36px;
    height: 36px;
}

.saveAsInputControlsSelectTab {
    composes: saveAsInputControls;
    color: lightgray;
}

.saveAsNewTabHelperText {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
}

.replaceTabItem {
    white-space: pre !important;
}
