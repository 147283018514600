.root {
    background-color: #eee;
}

.useNameContainer {
    padding: 15px;
    padding-top: 25px;
}

.userName {
    margin: 5px;
    font-weight: bold;
    font-size: 18px;
    margin-left: 10px;
}

.button {
    margin: 5px;
}

.dialogContent {
    padding: 0 !important;
}
