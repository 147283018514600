.fieldContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 7px 0px 7px 0px;
}

.fieldLabel {
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
}

.fieldValue {
    width: 50%;
    padding: 1px 3px 0 0;
    overflow: hidden !important;
    text-align: end;
    font-size: 0.9rem;
    font-weight: 500;
    width: 50%;
}

.hightlightFieldLabel {
    font-weight: 500;
}

.hightlightFieldValue {
    font-size: 1.1rem;
}
