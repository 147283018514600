.listItem {
    border-bottom: solid 1px #e2e2e2;
}

.separatorBorder {
    border-bottom: solid 8px #757575;
}

.listItemDragging {
    background: #ccc !important;
}

.dragHandle {
    padding-left: 10px;
}

.disabledItem {
    color: lightgray;
}

.transferBasketContainer {
    padding-bottom: 8px;
}

.headerStyle {
    line-height: 1;
}
.subtitle2 {
    line-height: 1;
}
